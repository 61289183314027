import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useState} from "react";
import {CreateStackRequestDto} from "ps-letter-mgmtrest-client";
import {createStackRequest} from "../../store/app/app.slice";
import {Form, InputNumber, message, Modal, Result, Select} from "antd";

const { Option } = Select;
export default function CreateRequestStackDialog(props) {

    const dispatch = useDispatch();
    const params = useParams();
    const stackType = params?.stackType?.toUpperCase()
    const [category, setCategory] = useState([]);
    const [language, setLanguage] = useState([]);
    const [amount, setAmount] = useState(100);
    
    const hideModal = () =>  {
        props.onHideModal()
    }
    
    const handleOk = () =>  {
        const requestStackDto: CreateStackRequestDto = {
            requestedAmount: amount,
            requestedLanguages: language,
            requestedStackType: stackType?.toUpperCase()
        }
        dispatch(createStackRequest({
            finalData: {
                requestedStack: requestStackDto
            },
            callbackSuccess: (result) => {
                console.log(result)
                hideModal();
            },
            callbackError: (error) => {
                console.log(error)
                message.error({content: JSON.stringify(error)})
            },
        }));
    }
    
    return <Modal
            open={props.showModal}
            title={"Request Stack for " + stackType}
            onOk={handleOk}
            okText={"Ok"}
            onCancel={hideModal}
            //width={1000}
        >
            <Form.Item label="Enter amount of letters">
                <InputNumber value={amount}
                             onChange={setAmount}/>
            </Form.Item>

            {/*<Form.Item label="Categories">*/}
            {/*    <Select*/}
            {/*        mode="multiple"*/}
            {/*        style={{width: '100%'}}*/}
            {/*        allowClear*/}
            {/*        placeholder={"Choose Category"}*/}
            {/*        value={category}*/}
            {/*        onChange={setCategory}*/}
            {/*    >*/}
            {/*        <Option value="category1">Category 1</Option>*/}
            {/*        <Option value="category2">Category 2</Option>*/}
            {/*        <Option value="category3">Category 3</Option>*/}
            {/*    </Select>*/}
            {/*</Form.Item>*/}

            <Form.Item label="Languages">
                <Select
                    mode="multiple"
                    style={{width: '100%'}}
                    allowClear
                    placeholder={"Choose Language"}
                    value={language}
                    onChange={setLanguage}
                >
                    <Option value="english">English</Option>
                    <Option value="urdu">اردو (Urdu)</Option>
                </Select>
            </Form.Item>
        </Modal>
}