
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StackRequestsTable, AssignedStacksTable } from "../components/tables/StacksTables";
import {loadStacks, loadStackRequests, assignStackRequest, unassignStack, deleteStack} from "../store/app/app.slice";
import {useHistory,} from "react-router-dom";
import {message} from "antd";


function StacksOverview() {

  const dispatch = useDispatch();
  const { stackRequests, stacks } = useSelector((state) => state.app);

  const history = useHistory();
  
  const navigateToStackDetailsView = (record) => {
          history.push("/stacks/"+record.id) // stacks/:stackid
  }

  const fetchData = useCallback(() => {
    dispatch(loadStackRequests({}));
    dispatch(loadStacks({}));
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleRemoveRequest = (record) => {
    dispatch(deleteStack({
      finalData: {id: record.id},
      callbackSuccess: (response) => {
        console.log("Stack deleted successfully", response);
        fetchData();
        message.success("Stack deleted successfully")
      },
      callbackError: (error) => {
        console.error("Error deleting stack", error);
        message.error({content: error})
      }
    }))
  };
  
  const handleUnassignRequest = (record) => {
    dispatch(unassignStack({
      finalData: {stackId: record.id},
      callbackSuccess: (response) => {
        console.log("Stack unassigned successfully", response);
        fetchData();
        message.success("Stack unassigned successfully")
      },
      callbackError: (error) => {
        console.error("Error unassigning stack", error);
        message.error({content: error})
      }
    }));
  };
  
  const handleConfirmRequest = (data) => {
    //Reshape the data and send request using dispatch
    const requestData = {
      assignedStack: {
        id: data.id,
        assigneeId: data.requesterId,
        assigneeName: data.requesterName,
        assignedAmount: data.editedAmount,
        assignedLanguages: data.requestedLanguages ? data.requestedLanguages.filter(lang => lang != null) : [],
        assignmentType: data.assignmentType,
        assignedStackType: data.requestedStackType
      }
    }
    dispatch(assignStackRequest({
      finalData: requestData,
      callbackSuccess: (response) => {
        console.log("Stack request assigned successfully", response);
        fetchData();
        message.success("Stack assigned successfully")
      },
      callbackError: (error) => {
        console.error("Error assigning stack request", error);
        message.error({content: error})
      }
    }));
    console.log("Confirm Request Handler", data);
  };

  return (
    <>
      <StackRequestsTable
        stackRequests={stackRequests}
        handleRemoveRequest={handleRemoveRequest}
        handleConfirmRequest={handleConfirmRequest}
      />

      <AssignedStacksTable 
      assignedStacks={stacks} 
      navigateToStackDetailsView={navigateToStackDetailsView}
      handleUnassignRequest={handleUnassignRequest}
      fetchData={fetchData}
      />
      
    </>

  );
}

export default StacksOverview;
