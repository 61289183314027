import {Avatar, Comment, Card, Typography, Row, Col, Divider, Space, Button, message, Spin} from "antd";
import {UserDataDtoGenderEnum} from "acc-rest-client";
import React from "react";
import moment from "moment/moment";
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {calculate_age} from "../tables/LetterTables";
import {ProcessedLetter} from "ps-letter-mgmtrest-client";
import {StackDto} from "ps-letter-mgmtrest-client";
import {useDispatch, useSelector} from "react-redux";
import {updateLetter} from "../../store/app/app.slice";

const {Title} = Typography;
export default function StackLetter({letter, stack, onLetterProcessed}: {letter: ProcessedLetter, stack: StackDto}) {
    const dispatch = useDispatch();
    const {isLetterLoading} = useSelector((state) => state.app);
    const updateLetterFiltration = (presentToHudhur: any) => {
        dispatch(updateLetter(
            {
                finalData: {
                                id: letter.id,
                                updatedLetter: {
                                        id: letter.id,
                                        presentToHudhur: presentToHudhur,
                                }
                            },
                callbackSuccess: (result) => {
                    console.log(result)
                    onLetterProcessed(result)
                },
                callbackError: (error) => {
                    console.log(error)
                    message.error({content: JSON.stringify(error)})
                },
            }
          )
        )
    }
    
    
    const getFiltrationControls = () => {
        return <>
            <Spin spinning={isLetterLoading === true}>
                <Row align={"middle"} justify={"space-between"}>
                    <Col span={12} flex={"none"}>
                        <b>Current Value:</b> {letter.presentToHudhur ? <>Present to Hudhur<sup>ABA</sup></> : letter.presentToHudhur === false ?  <>Summarize Letter</> : "Not Defined"}
                    </Col>
                    <Col span={12} flex={"none"}>
                            <Button type="default" onClick={() => {updateLetterFiltration(null)}}>Skip</Button>
                    </Col>
                </Row>
            <Divider/>
            <Row align={"middle"} justify={"center"}>
                <Col span={24} flex={"none"}>
                    <Space align={ "center"}>
                    <Button type="primary" onClick={() => {updateLetterFiltration(true)}}>Present to Hudhur<sup>ABA</sup></Button>
                    <Button type="default" className={"tag-badge"} onClick={() => {updateLetterFiltration(false)}}>Summarize Letter</Button>
                    </Space>
                </Col>
            </Row>
                </Spin>
        </>
            }


    const getStackControls = () => {
        switch (stack?.assignedStackType) {
            case"FILTRATION":
                return getFiltrationControls()
            case"SUMMARY":
                return <div>no</div>
            case"ANSWER":
                return <div>no</div>
        }
    }
    
    return <>
        <Row justify={"start"} style={{height: "100%"}}>
            <Col span={12}>
                <div style={{height: "100%"}}>
                    <Card style={{height: "100%"}}>
                        <Comment
                            content={<>
                                <div>
                                    <Row>
                                        <Col span={16}>
                                            <Avatar.Group>
                                                <div style={{marginRight: "3px"}}>
                                                    <Avatar
                                                        src={letter.originalLetter.sender.gender ? letter.originalLetter.sender.gender === UserDataDtoGenderEnum.Male ? "https://cdn-icons-png.flaticon.com/512/10031/10031337.png" : letter.originalLetter.sender.gender === UserDataDtoGenderEnum.Female ? "https://cdn-icons-png.flaticon.com/512/4939/4939798.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png"}
                                                        shape="square"
                                                        size="large">{letter.originalLetter.sender.gender}</Avatar>
                                                </div>
                                                <div className="avatar-info">
                                                    <Title
                                                        level={5}>{letter.originalLetter.sender.firstName + " " + letter.originalLetter.sender.lastName} ({calculate_age(letter.originalLetter.sender.birthdate)})</Title>
                                                    <p>
                                                        <span>{getUnicodeFlagIcon(letter.originalLetter.sender.country?.values?.flag)} {letter.originalLetter.sender.country ? letter.originalLetter.sender.country.defaultLabel : ""} </span>{letter.originalLetter.sender.jamaat ? "(" + letter.originalLetter.sender.jamaat + ")" : "no jamaat"}
                                                    </p>
                                                </div>
                                            </Avatar.Group>
                                        </Col>
                                        <Col span={8}>
                                            <div className="avatar-info">
                                                <p>{moment.utc(letter.originalLetter.metaData.createdAt).format("DD-MM-yyyy")}</p>
                                                <p>{letter.originalLetter.category + "/" + letter.originalLetter.language}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <p>{letter.originalLetter.content}</p>
                                </div>
                            </>}
                        />
                    </Card>
                </div>
            </Col>
            <Col span={12}>
                    <div style={{height: "100%"}}>
                        <Card style={{height: "100%"}}>
                                {getStackControls(stack)}
                        </Card>
                    </div>
            </Col>
        </Row>
    </>
}