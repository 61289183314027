import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {loadStaffMembers, reassignStack} from "../../store/app/app.slice";
import {Form, message, Modal, Select} from "antd";

const { Option } = Select;
export default function ReassignStackDialog(props) {

    const dispatch = useDispatch();
    const {staffMembers, isStaffMembersLoading} = useSelector((state) => state.app);
    const [selectedStaffMember, setSelectedStaffMember] = useState(undefined);
    const params = useParams();
    
    useEffect(() => {
        loadAllStaffMembers();
    }, []);
    
    const hideModal = () =>  {
        props.onHideModal()
    }
    
    const loadAllStaffMembers = () => {
        dispatch(loadStaffMembers({
            finalData: {},
            callbackSuccess: (result) => {
                console.log(result)
            },
            callbackError: (error) => {
                console.log(error)
                message.error({content: JSON.stringify(error)})
            },
        }));
    }
    
    const handleOk = () =>  {
        
        dispatch(reassignStack({
            finalData: {
                stackId: props.stackId,
                staffMember: staffMembers.find(member => member.id === selectedStaffMember)
            },
            callbackSuccess: (result) => {
                console.log(result)
                if(props.fetchData) props.fetchData()
                hideModal();
                message.success("Stack reassigned successfully")
            },
            callbackError: (error) => {
                console.log(error)
                message.error({content: JSON.stringify(error)})
            },
        }));
    }
    
    return <Modal
            open={props.showModal}
            title={"Reassign Stack #" + props.stackId}
            onOk={handleOk}
            okText={"Ok"}
            onCancel={hideModal}
            //width={1000}
        >
            <Form.Item label="New Asignee">
                <Select
                    mode="single"
                    style={{width: '100%'}}
                    allowClear
                    placeholder={"Select Member for assignment"}
                    value={selectedStaffMember}
                    onChange={setSelectedStaffMember}
                    options={staffMembers?.map(member => ({value: member.id, label: member.firstName + " " + member.lastName}))}
                >
                </Select>
            </Form.Item>
        </Modal>
}