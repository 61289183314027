import {Avatar, Button, Card, message, notification, Space, Table, Typography} from "antd";
import {getCategoryColor, getStatusColor} from "./StacksTables";
import {UserDataDtoGenderEnum} from "acc-rest-client";
import {calculate_age} from "./LetterTables";
import {FileDoneOutlined, PlusOutlined} from "@ant-design/icons";
import {useHistory, useParams} from "react-router-dom";
import {ProcessedLetter} from "ps-letter-mgmtrest-client";
import {deliverStack, loadMyStacks} from "../../store/app/app.slice";
import {useDispatch} from "react-redux";

const { Title } = Typography;
export default function StackDetailTable({ stack, letters, showNewStackRequestModal }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    
    const navigateToLetterView = (record: ProcessedLetter) => {
            history.push("/process/"+params?.stackType+"/stacks/"+params?.stackId+"/"+record.id)
    }

    const navigateToStacksView = () => {
        history.push("/process/"+params?.stackType+"/stacks")
    }
    
    const columnsLetters = [
        {
            title: "Name",
            dataIndex: "originalLetter",
            //sorter: (a, b) => a.projectType?.translations?.en?.localeCompare(b.projectType?.translations?.en),
            render: (letter) => (letter ?

                <Avatar.Group>
                    <div style={{marginRight: "3px"}}>
                        <Avatar
                            src={letter.sender.gender ? letter.sender.gender === UserDataDtoGenderEnum.Male ? "https://cdn-icons-png.flaticon.com/512/10031/10031337.png" : letter.sender.gender === UserDataDtoGenderEnum.Female ? "https://cdn-icons-png.flaticon.com/512/4939/4939798.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png"}
                            shape="square" size="large">{letter.sender.gender}</Avatar>
                    </div>
                    <div className="avatar-info">
                        <Title
                            level={5}>{letter.sender.firstName + " " + letter.sender.lastName}</Title>
                        <p>{letter.sender.email}</p>
                    </div>
                </Avatar.Group> : "N/A"),
        },
        {
            title: "Country",
            dataIndex: ["originalLetter" ,"sender","country", "defaultLabel" ],
            key: "country",
        },
        {
            title: "Jamaat",
            dataIndex: ["originalLetter", "sender","jamaat", "defaultLabel" ],
            key: "jamaat",
        },
        {
            title: "Category",
            dataIndex: ["originalLetter", "category"],
            key: "category",
            render: (category) => (
                <Button type="text" style={{color: getCategoryColor(category),borderColor:getCategoryColor(category) ,borderStyle: 'solid'}}>
                    {category}
                </Button>
            ),
        },
        {
            title: "Age",
            dataIndex: ["originalLetter", "sender","birthdate" ],
            render: (birthdate) => (calculate_age(birthdate)),
        },
        {
            title: "Content",
            dataIndex: ["originalLetter", "content"],
            key: "content",
            render: (content) => (content.substring(0, 20) + " ...")
        },
        {
            title: "Hudhur",
            dataIndex: ["presentToHudhur"],
            key: "hudhur",

            render: (presentToHudhur) => (
                <div style={{
                    backgroundColor: '#f0f0f0',
                    color: presentToHudhur ? 'green' : presentToHudhur === false ? 'red' : 'grey',
                    borderRadius: '30%',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontWeight: 'bold'
                }}>
                    {presentToHudhur ? '✓' : presentToHudhur === false ? '❌' : ''}
                </div>
            ),
        },
        {
            title: "Status",
            key: "status",
            dataIndex: ["internalStatus"],
            render: (status) => (
                <Button type="text" style={{ backgroundColor: getStatusColor(status),color:'white' }}>
                    {status}
                </Button>
            ),
        },
        {
            title: "By",
            dataIndex: ["assignedTo"],
            key: "by",
        },
    ];

    const handleDeliverStack = () => {
        dispatch(deliverStack({
            finalData: {stackId: params?.stackId},
            callbackSuccess: (response) => {
                console.log("Stack delivered successfully", response);
                notification['success']({
                    message: 'Stack delivered',
                    description:
                        'Stack #'+stack.id+' has been transmitted to supervisor.',
                });
                navigateToStacksView();
            },
            callbackError: (error) => {
                console.error("Error delivering stack", error);
                notification['error']({
                    message: 'Error delivering stack',
                    description:error,
                });
            }
        }));
    };
    
    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={"Stack #"+stack.id}
            extra={<Space>
                <Button className={"ant-tag-orange"} type="default" onClick={showNewStackRequestModal}><PlusOutlined/> New Stack</Button>
                <Button className={"ant-tag-green"} type="default" onClick={handleDeliverStack}><FileDoneOutlined /> Deliver Stack</Button>
            </Space>}
        >
            <div className="table-responsive">
                <Table
                    columns={columnsLetters}
                    dataSource={letters} // Wrap in array as it's a single object
                    pagination={false}
                    className="ant-border-space"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {navigateToLetterView(record)}, // click row
                            onDoubleClick: event => {}, // double click row
                            onContextMenu: event => {}, // right button click row
                            onMouseEnter: event => {}, // mouse enter row
                            onMouseLeave: event => {}, // mouse leave row
                        };
                    }}
                />
            </div>
        </Card>
    );
}